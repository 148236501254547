import React, {
  MutableRefObject,
  Ref,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import { Grid, QuestionBoxFullWidth } from "../../../components";
import Citations from "../../../components/Citations/Citations";
import { Flavour, Question, QuestionResponse } from "../../../interface";
import {
  ImageSkeleton,
  TitleSkeleton,
  ParagraphSkeleton,
} from "../../../components/Skeleton";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import MediaLinks from "../../../components/MediaLinks/MediaLinks";
import { motion, useScroll, useTransform, useInView, circOut, cubicBezier, useMotionValueEvent } from "framer-motion";
import useMediaQuery from "../../../hooks/useMediaQuery";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import ColourSelect from "../../../components/ColourSelect/ColourSelect";

export const ShortHero = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      giveFeedback,
      setNextTemplate,
      nextTemplate,
      questions,
      flavours,
      testingTemplates
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      giveFeedback?: (feedback_type: -1 | 1) => Promise<void>;
      setNextTemplate: React.Dispatch<
        React.SetStateAction<Ref<HTMLDivElement>>
      >;
      nextTemplate: Ref<HTMLDivElement>;
      questions: Question[];
      flavours: Flavour[];
      testingTemplates: boolean;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState(false);
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
    const heroShortRef = useRef(null);
    const questionRef = useRef<null | HTMLDivElement>(null);
    const questionIsInView = useInView(questionRef)

    useEffect(() => {
      setNextTemplate(heroShortRef);
    }, []);

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    const [templateColour, setTemplateColour] = useState('white');
    useEffect(() => {
      if (testingTemplates) {
        if (templateColour === "white") {
          setVersion("white");
        }
        if (templateColour === "dark") {
          setVersion("dark");
        }
        if (templateColour === "grey") {
          setVersion("grey");
        }
      }
    }, [templateColour]);


    const { scrollYProgress: mainYProgress } = useScroll({
      target: heroShortRef,
      offset: ["start center", "end start"],
      // offset: ["center end", "end center"],
    });

    const opacityProgress = useTransform(mainYProgress, [1, 0.6], [0.6, 1]);
    const scaleProgress = useTransform(mainYProgress, [1, 0.6], ["90%", "100%"]);
    const backgroundY = useTransform(mainYProgress, [0.6, 1], ["0%", "30%"]);

    const { scrollYProgress: contentYProgress } = useScroll({
      target: heroShortRef,
    });
    const { scrollYProgress: questionYProgress } = useScroll({
      target: heroShortRef,
      offset: ["center end", "end center"],
    });

    // const y = useTransform(contentYProgress, [0, 1], ["1%", "-30%"]);
    const y = useTransform(contentYProgress, [0, 1], ["1%", document.body.clientHeight < 2100 ? "-60%" : "-40%"]);
    const questionY = useTransform(questionYProgress, [1, 0], ["-100%", "0%"]);

    return (
      <motion.div
        ref={heroShortRef}
        id="short-hero"
        className={classNames("relative desktop:rounded-t-[1.5rem] z-40", {
          ["-mt-[2.5rem] rounded-t-[1.5rem]"]: templateOrderIndex !== 0,
        })}
        style={(flavours.length - 1) !== templateOrderIndex && questions.length > 1 ? { y: backgroundY, scale: scaleProgress, opacity: opacityProgress } : {}}
      >
        <div className="desktop:h-[200vh]">
          <div className={classNames(
            "desktop:sticky desktop:top-0 desktop:h-screen overflow-hidden rounded-t-[1.5rem]",
            {
              ["bg-white"]: version === "white",
              ["bg-grey-200"]: version === "grey",
              ["bg-grey-1000 text-white"]: version === "dark",
            }
          )}>
            <motion.div style={useMediaQuery('(min-width: 1280px)') ? { y: y } : {}}>
              <Grid
                customClasses={classNames(
                  "gap-x-4 relative desktop:!mx-0 rounded-t-[1.5rem]",
                  {
                    ["bg-white"]: version === "white",
                    ["bg-grey-200"]: version === "grey",
                    ["bg-grey-1000 text-white"]: version === "dark",
                  }
                )}
              >
                <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-23 desktop:col-span-16 w-full h-full mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] desktop:pb-[42px] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 pb-[5.5rem]">
                  <h2
                    id="short-hero-tagline"
                    className={classnames(
                      "mobile:text-[1rem] desktop:text-lg font-medium uppercase",
                      {
                        ["!text-text-greyHeader"]: version === "white" || version === "grey",
                        ["!text-white"]: version === "dark",
                      }
                    )}
                  >
                    {query}
                  </h2>
                  {testingTemplates && (
                    <ColourSelect templateColour={templateColour} setTemplateColour={setTemplateColour}></ColourSelect>
                  )}
                  <div className="pt-[36px]">
                    {!content.header ? (
                      <TitleSkeleton />
                    ) : (
                      <h3
                        id="short-hero-header"
                        className={classnames(
                          "mobile:text-4xl desktop:text-[40px] font-medium",
                          {
                            ["!text-text-greyHeader"]: version === "white" || version === "grey",
                            ["!text-white"]: version === "dark",
                          }
                        )}
                      >
                        {content.header}
                      </h3>
                    )}
                  </div>
                </div>
                <div
                  id="short-hero-container"
                  className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-24"
                >
                  {!content.imgURL ? (
                    <div>
                      <div className="mobile:hidden tablet:block desktop:block">
                        <ImageSkeleton display="hero-short" />
                      </div>
                      <div className="tablet:hidden desktop:hidden">
                        <ImageSkeleton display="hero-short-mobile" />
                      </div>
                    </div>
                  ) : (
                    <>
                      {!content.imgURL ? (
                        <div className="w-full mobile:h-[250px] tablet:h-[400px] max-h-[400px] bg-gradient-to-b from-[#8B919A] to-white"></div>
                      ) : (
                        <div
                          id="short-hero-image"
                          style={{ backgroundImage: `url('${content.imgURL}')` }}
                          className={`z-3 w-full mobile:h-[250px] tablet:h-[400px] max-h-[400px] bg-cover bg-center bg-no-repeat`}
                        ></div>
                      )}
                    </>
                  )}
                </div>

                <div
                  id="short-hero-answer-content"
                  className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-23 desktop:col-span-16 w-full h-full mobile:pt-[1.375rem] desktop:pt-[1.75rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 desktop:pb-[72px]"
                >
                  <div
                    id="short-hero-paragraph-content-1"
                    className={classnames(
                      "pt-[1.375rem]",
                      {
                        ["!text-text-greyText"]: version === "white" || version === "grey",
                        ["!text-white"]: version === "dark",
                      }
                    )}>
                    {!content.paragraphs[0] ? (
                      <ParagraphSkeleton />
                    ) : (
                      <TypewriterText
                        id="short-hero-paragraph-1"
                        className="mobile:text-sm desktop:text-lg font-light"
                        hasAllText={content.paragraphs[0].done}
                        text={
                          content.paragraphs[0].text[
                            content.paragraphs[0].text.length - 1
                          ] === "."
                            ? content.paragraphs[0].text
                            : `${content.paragraphs[0].text}.`
                        }
                        isDone={para1Done}
                        setIsDone={setPara1Done}
                      />
                    )}
                  </div>
                </div>
              </Grid>
              <div data-testid="media-links-v2" className="hidden" />
              {para1Done && (
                <>
                  <Citations metadata={response.metadata} version={version} usedSnippetIds={response.used_snippet_ids} />
                  <MediaLinksV2 assets={assets} display="fullwidth" version={version} />
                </>
              )}

            </motion.div>
            {/* <div className="mobile:hidden desktop:grid desktop:grid-cols-24 w-full absolute bottom-16">
              <div
                className={classNames("desktop:col-start-2 desktop:col-span-full", {
                  ["desktop:translate-y-0 desktop:transition-all desktop:duration-300 desktop:delay-100 desktop:visible"]:
                    para1Done,
                  ["desktop:translate-y-full desktop:invisible desktop:h-0"]:
                    !para1Done,
                })}
              >
                {version === "white" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="white"
                  />
                )}
                {version === "dark" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="dark"
                  />
                )}
                {version === "grey" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="grey"
                  />
                )}
              </div>
            </div> */}
          </div>
        </div>

        <div className={classNames({ ["!hidden"]: !para1Done })}>
          <motion.div
            ref={questionRef}
            style={useMediaQuery('(min-width: 1280px)') && para1Done && followUps.length > 0 ? { y: questionY } : {}}
            className={classNames("w-full mobile:relative desktop:absolute z-40 rounded-t-[1.5rem] mobile:-mt-[3.125rem] desktop:-mt-0",
              {
                ["hidden"]: hideQuestion,
              }
            )} >
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para1Done}
              onSubmit={askQuestion}
              giveFeedback={giveFeedback}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}
              customMargin="!mx-0"
            />
          </motion.div>
        </div>
      </motion.div>
    );
  }
);
