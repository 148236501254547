import React from 'react';
import classnames from "classnames";
import { Grid } from "../";

export type Citations = {
  page_url?: string;
};

const Citations = ({
  metadata,
  noGrid,
  version,
  usedSnippetIds = [],
}: {
  metadata: Citations[],
  noGrid?: boolean,
  version: string,
  usedSnippetIds: string[]
}) => {
  let usedCitations: Citations = [];

  metadata.forEach((item) => {
    const isUsed = usedSnippetIds.some((id) => id === item.doc_id);
    if (isUsed) usedCitations.push(item);
  })

  console.log('metadata', metadata);
  console.log('usedSnippetIds', usedSnippetIds);

  return (
    <>
      {
        noGrid ? (
          <div
            className={classnames(
              "mobile:my-[36px] tablet:my-[36px] mobile:text-xs desktop:text-sm desktop:pb-[44px] mobile:col-start-0 desktop:col-start-2 mobile:col-span-full desktop:1 desktop:row-start-0 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0",
              {
                ["text-text-greyText "]: version === "white" || version === "grey",
                ["text-white"]: version === "dark",
              }
            )}
          >
            <h5>CITATIONS</h5>
            <ul>
              {
                usedCitations.map(({ page_url }: { page_url: string }) => {
                  return (
                    <li>
                      < a href={page_url} target="_blank">{page_url}</a>
                    </li>
                  )
                })
              }
            </ul>
          </div >
        ) : (
          <Grid>
            <div
              className={classnames(
                "mobile:my-[36px] tablet:my-[36px] mobile:text-xs desktop:text-sm text-text-greyText desktop:pb-[44px] mobile:col-start-0 desktop:col-start-2 mobile:col-span-full desktop:1 desktop:row-start-0 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0",
                {
                  ["text-text-greyText "]: version === "white" || version === "grey",
                  ["text-white"]: version === "dark",
                }
              )}
            >
              <h5>CITATIONS</h5>
              <ul>
                {
                  usedCitations.map(({ page_url }: { page_url: string }) => {
                    return (
                      <li>
                        < a href={page_url} target="_blank">{page_url}</a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </Grid>
        )}
    </>

  );
};

export default Citations;