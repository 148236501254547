import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cross, CircleStars, MovingSpinningCircles } from '../../components/Icons';
import classnames from 'classnames';
import { TypewriterText } from '../../components';
import { useConfig } from '../../context/config-context';

export default function Interim({
  onSubmit,
}: {
  onSubmit: (value: string) => void;
}) {
  const { config } = useConfig();
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [questionAsked, setQuestionAsked] = useState(false);
  const [initialTextDone, setInitialTextDone] = useState(false);
  const [questionsDone, setQuestionsDone] = useState(false);
  const [startInitial, setStartInitial] = useState(false);

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    console.log("value", value);
    e.preventDefault();
    setQuestionAsked(true);
    onSubmit(value);
    setValue('');
  };

  const handleClose = () => {
    window.parent.postMessage('CLOSE', config.HOST);
    navigate('/');
  };

  // Simulating initial animations without useEffect
  setTimeout(() => {
    setExpanded(true);
  }, 200);
  setTimeout(() => {
    setStartInitial(true);
  }, 1000);

  return (
    <>
      <div id="interim" className="w-full h-full text-white">
        <div className="flex flex-col content-center h-full w-full justify-center items-center">
          <div className="w-full smallscreenheight:pt-[0] mobile:pt-[3.75rem] tablet:pt-[1.625rem] desktop:pt-[1.375rem]">
            <form
              className={classnames('mobile:px-[1.375rem] tablet:px-[3.125rem] desktop:px-[5.375rem]', {
                'hidden ': !expanded && !questionAsked,
              })}
              onSubmit={handleSubmit}
            >
              <div
                className={classnames('flex border-b-[1px] py-4 mobile:mt-[5px]', {
                  'hidden ': questionAsked,
                })}
              >
                <input
                  aria-label="Search"
                  className="animate-fade text-white placeholder-white flex w-full bg-transparent p4 mobile:text-xl desktop:text-2xl tablet:text-base outline-none"
                  placeholder="Ask me a question..."
                  onChange={onChange}
                  value={value}
                />
                <button
                  aria-label="Enter"
                  disabled={value.length === 0}
                  className={classnames(
                    'font-bold mobile:text-sm desktop:text-lg tablet:text-base focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white',
                    { 'text-grey-500': !value }
                  )}
                >
                  enter
                </button>
              </div>
            </form>
            <div className={classnames('flex mobile:pt-[2.25rem] desktop:pt-[4.125rem] mobile:px-[2.5rem] tablet:px-[8.625rem] desktop:px-[21.75rem]', { 'flex-col': expanded })}>
              <div
                className={classnames('flex h-[40px] relative w-full justify-center items-center', {
                  'hidden ': !expanded,
                  '': expanded && !questionAsked,
                })}
              >
                <MovingSpinningCircles />
              </div>
              <div
                className={classnames('flex w-full justify-center', {
                  'hidden ': !expanded || questionAsked,
                })}
              >
                <div
                  className="font-bold ml-4 mobile:ml-[0] text-[#BFBFBF] text-center mobile:text-xl desktop:text-2xl tablet:text-base pt-[1.75rem]"
                >
                  Or I have a few suggestions to get started:
                </div>
              </div>
            </div>
          </div>
          <div
            className={classnames('desktop:grid desktop:grid-cols-3 tablet:grid-cols-3 gap-x-4 w-full mobile:px-[3.125rem] desktop:px-[5.375rem]', {
              hidden: !expanded || questionAsked,
            })}
          >
            {config.INITIAL_QUESTIONS.map((question) => (
              <div
                className="flex flex-row tablet:flex-col desktop:flex-col items-center"
                key={question.replaceAll(' ', '')}
              >

                <div className="hidden">{question}</div>
                <div className="flex w-full">
                  <button
                    aria-label={question}
                    onClick={() => {
                      setQuestionAsked(true);
                      onSubmit(question);
                      setQuestionsDone(true); // Simulate TypewriterText completion
                    }}
                    className="hover:text-white w-full pt-[2.25rem] text-[#BFBFBF] desktop:text-[1.063rem] tablet:text-sm mobile:text-sm desktop:text-lg font-normal text-center tablet:leading-[1.688rem] focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white"
                  >
                    <div className='animate-fade'>
                      {question}
                    </div>
                    {/* <TypewriterText hasAllText isDone={questionsDone} setIsDone={setQuestionsDone} text={question} /> */}
                  </button>
                </div>
              </div>
            ))}
          </div>
          <p
            className={classnames(
              "text-black smallscreenheight:pt-[3.75rem] text-[14px] leading-[1.5] text-[#BFBFBF] font-normal text-center pt-[2.5rem] mobile:pt-[72px] mobile:px-[3.125rem] tablet:px-[3.125rem] desktop:px-[8.25rem]",
              {
                '!text-[#BFBFBF] animate-fade': startInitial,
              }
            )}>
            The responses provided by GXM are generated using artificial intelligence (AI) and are based on the information available on this website.
            While we strive for accuracy, the content may not always reflect the most current or complete data. Please verify critical information
            independently. By using this service, you acknowledge and accept the WPP’s&nbsp; 
            <a href="https://urldefense.com/v3/__https:/www.wpp.com/en-au/terms-and-conditions__;!!BupLon6U!qYfoxzGRG5KwHz517lMntK7HGfsfn1jAdYOspfROlPIG41y-M5slYOind6A1q_6nW9noWtuOEpNWntZV8CMakmmnGyFT%24" target="_blank" className='underline font-semibold'>Terms & Conditions</a>
            &nbsp;and&nbsp;<a href="https://urldefense.com/v3/__https:/www.wpp.com/en-au/privacy-policy__;!!BupLon6U!qYfoxzGRG5KwHz517lMntK7HGfsfn1jAdYOspfROlPIG41y-M5slYOind6A1q_6nW9noWtuOEpNWntZV8CMakvnkuVWl%24" target="_blank"  className='underline font-semibold'>Privacy Policy</a></p>
        </div>
      </div>
      {/* <div id="interim" className="backdrop-blur-sm bg-center h-screen w-full">
        <div className="w-full h-full absolute bottom-0 bg-black/50 backdrop-blur-md">
          <div
            className={classnames(
              'flex content-center justify-center items-center bg-[#000000] opacity-80 z-50 text-white',
              {
                'top-[30%] -translate-y-[30%] translate-x-[2.5%] transition-all duration-500 desktop:w-[calc(100%-240px)] relative h-full desktop:min-h-screen  desktop:mt-[2.5%] rounded-t-[24px]':
                  questionAsked,
                'desktop:w-[calc(100%-240px)] fixed inset-x-0 bottom-0 desktop:px-[122px] desktop:py-[85px] desktop:mx-[120px] flex-col p-[5%] rounded-t-[24px]':
                  !questionAsked,
              }
            )}
          >
            <div
              className={classnames('absolute right-8 top-8', {
                'hidden ': !expanded && !questionAsked,
              })}
            >
              <button
                className={classnames(
                  'cursor-pointer focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white',
                  {
                    'hidden ': !expanded || questionAsked,
                  }
                )}
                onClick={handleClose}
                aria-label="Close"
              >
                <Cross crossColour="fill-icons-crosslight" />
              </button>
            </div>
            <div className="w-full">
              <form
                className={classnames('', {
                  'hidden ': !expanded && !questionAsked,
                })}
                onSubmit={handleSubmit}
              >
                <div
                  className={classnames('flex border-b-[1px] py-4 mobile:mt-[5px]', {
                    'hidden ': questionAsked,
                  })}
                >
                  <input
                    aria-label="Search"
                    className="animate-fade flex w-full bg-transparent p4 desktop:text-2xl tablet:text-base outline-none"
                    placeholder="Ask me a question in any language..."
                    onChange={onChange}
                    value={value}
                  />
                  <button
                    aria-label="Enter"
                    className={classnames(
                      'font-light desktop:text-2xl tablet:text-base focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white',
                      { 'text-grey-500': !value }
                    )}
                  >
                    enter
                  </button>
                </div>
              </form>
              <div className={classnames('flex', { 'flex-col': expanded })}>
                <div
                  className={classnames('flex h-[30px] relative w-full justify-center items-center mt-[30px]', {
                    'hidden ': !expanded,
                    'pt-[50px] ': expanded && !questionAsked,
                  })}
                >
                  <MovingSpinningCircles />
                </div>
                <div
                  className={classnames('flex w-full h-[30px] justify-center', {
                    'hidden ': !expanded || questionAsked,
                  })}
                >
                  {expanded && startInitial && (
                    <TypewriterText
                      className="ml-4 text-white h-[5px] desktop:text-2xl tablet:text-base pt-[20px]"
                      isDone={initialTextDone}
                      setIsDone={setInitialTextDone}
                      hasAllText
                      text="Or I have a few suggestions to get started:"
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={classnames('grid desktop:grid-cols-3 tablet:grid-cols-3 gap-x-4 h-full w-full mobile:mt-4', {
                hidden: !expanded || questionAsked,
              })}
            >
              {config.INITIAL_QUESTIONS.map((question) => (
                <div
                  className="mobile:mt-[18px] flex flex-row tablet:flex-col desktop:flex-col items-left mobile:items-center"
                  key={question.replaceAll(' ', '')}
                >
                  
                  <div className="hidden">{question}</div>
                  <div className="flex w-full">
                    <button
                      aria-label={question}
                      onClick={() => {
                        setQuestionAsked(true);
                        onSubmit(question);
                        setQuestionsDone(true); // Simulate TypewriterText completion
                      }}
                      className="desktop:mt-[18px] tablet:mt-[18px] desktop:text-[1.063rem] tablet:text-sm mobile:text-[12px] text-left tablet:leading-[1.688rem] focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white"
                    >
                      {initialTextDone && <TypewriterText hasAllText isDone={questionsDone} setIsDone={setQuestionsDone} text={question} />}
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <p className="text-[10px] text-center pt-16">The responses provided by GXM are generated using artificial intelligence (AI) and are based on the information available within our system. While we strive for accuracy, the content may not always reflect the most current or complete data. Please verify critical information independently. By using this service, you acknowledge and accept these terms.</p>
          </div>
        </div>
      </div> */}
    </>
  );
}