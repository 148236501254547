import React from 'react';
import { Button, Grid, TypewriterText } from "../";
import { ArrowRightIcon, StaticSpinningCircles } from "../Icons";
import { Fragment, MutableRefObject, Ref, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { OneLineQuestionSkeleton } from "../Skeleton";
import classnames from "classnames";
import { QuestionSkeletonFullWidth } from "../Skeleton/QuestionSkeletonFullWidth";
import { useChat } from "../../context/chat-context";

export const QuestionBoxFullWidth = ({
  templateOrderIndex,
  followupQuestions = [],
  placeholder = "Ask a follow up or new question",
  style = "default",
  optionQuestion = "",
  backgroundColour = "white",
  isLoading,
  onSubmit,
  giveFeedback,
  nextTemplate,
  sethideQuestion,
  customMargin
}: {
  templateOrderIndex: number;
  followupQuestions?: string[];
  placeholder?: string;
  style: "default" | "darkTxt" | "lightTxt";
  optionQuestion?: string;
  isLoading?: boolean;
  onSubmit: (value: string, is_selected_follow_up?: boolean) => void;
  giveFeedback?: (feedback_type: -1 | 1) => Promise<void>;
  backgroundColour: string;
  nextTemplate?: MutableRefObject<HTMLDivElement>;
  sethideQuestion: React.Dispatch<React.SetStateAction<boolean>>;
  customMargin?: string;
}) => {
  const { questions, loading: streaming, resumed, finished } = useChat();
  const [chosenFollowUpQuestion, setChosenFollowUpQuestion] = useState("");
  const [value, setValue] = useState("");
  const [buttonTextDone, setButtonTextDone] = useState(false);
  const [optionQuestionDone, setOptionQuestionDone] = useState(false);

  const isLastQuestionBox = templateOrderIndex + 1 === questions.length;
  const showInput = isLastQuestionBox && !chosenFollowUpQuestion;

  useEffect(() => {
    if (!isLastQuestionBox) {
      sethideQuestion(true);
    } else {
      if (resumed) {
        sethideQuestion(false);
      }
    }
  }, [isLastQuestionBox]);

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(value);
    setValue("");
    console.log("onSubmitTest");
  };

  useEffect(() => {
    if (isLoading) {
      if (nextTemplate) {
        nextTemplate.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    } else {
      scrollNext();
    }
  }, [nextTemplate]);

  // const hideQuestion = () => {
  //   sethideQuestion(false);
  //   localStorage.setItem('hideQuestion', JSON.stringify(true));
  // };

  const scrollNext = async () => {
    // await hideQuestion();
    if (nextTemplate) {
      nextTemplate.current?.scrollIntoView({
        block: "start",
        inline: "center",
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      id="question-component"
      data-testid="submit-question"
      className={`bg-${backgroundColour} rounded-t-[1.5rem] desktop:bg-transparent`}
    >
      <Grid
        customClasses={`bg-${backgroundColour} rounded-t-[1.5rem] gap-x-4 h-full relative question-component pt-[2.25rem] mobile:pb-[3.125rem] desktop:pb-[4rem] ${customMargin}`}
      >
        <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-12 flex desktop:flex-row mobile:flex-col mobile:pb-[2rem] tablet:pb-[1.875rem] desktop:pb-0 mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0">
          <div className="mobile:self-center tablet:self-start tablet:-ml-4 desktop:-ml-6">
            <StaticSpinningCircles />
          </div>
          {isLoading ? (
            <OneLineQuestionSkeleton />
          ) : (
            <>
              {optionQuestion && (
                <TypewriterText
                  id="optional-returned-question"
                  className={classnames(
                    "text-lg font-bold mobile:pt-[5px] tablet:pt-[1.5rem] desktop:pt-[0.625rem] desktop:pb-0 desktop:self-start",
                    {
                      ["text-black"]:
                        style === "default" || style === "darkTxt",
                      ["text-white"]: style === "lightTxt",
                    }
                  )}
                  text={optionQuestion}
                  isDone={optionQuestionDone}
                  setIsDone={setOptionQuestionDone}
                  hasAllText
                />
              )}
              {optionQuestion.length <= 0 && (
                <TypewriterText
                  id="optional-returned-question"
                  className={classnames(
                    "mobile:text-[1.125rem] tablet:text-[1.25rem] font-normal mobile:py-[1.75rem] desktop:pt-[0.625rem] desktop:pb-0 desktop:self-start",
                    {
                      ["text-black"]:
                        style === "default" || style === "darkTxt",
                      ["text-white"]: style === "lightTxt",
                    }
                  )}
                  text="You might be interested in:"
                  isDone={optionQuestionDone}
                  setIsDone={setOptionQuestionDone}
                  hasAllText
                />
              )}
            </>
          )}
        </div>
        <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-13 desktop:col-end-24 mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0">
          <div>
            {isLoading ? (
              <div className="pb-[1.5rem]">
                <QuestionSkeletonFullWidth />
              </div>
            ) : (
              <>
                <div className="flex flex-col">
                  <div className={`w-full ${finished ? 'visible' : 'invisible'}`}>
                    {followupQuestions?.map((text, i) => {
                      return (
                        <Fragment key={i}>
                          {style === "default" ? (
                            <Button
                              style={
                                chosenFollowUpQuestion === text
                                  ? "active"
                                  : "primary"
                              }
                              typewriter={{
                                id: "question-box-buttons",
                                isDone: buttonTextDone,
                                setIsDone: setButtonTextDone,
                                textReady: optionQuestionDone,
                              }}
                              text={text}
                              onClick={() => {
                                // don't allow same question twice - may want a disabled style
                                if (
                                  streaming ||
                                  chosenFollowUpQuestion === text
                                ) {
                                  return;
                                }
                                setChosenFollowUpQuestion(text);
                                onSubmit(text, true);
                                // scrollNext();
                              }}
                              classes="mr-[0.875rem] mobile:mr-[0] mb-[0.75rem] mobile:text-left px-2"
                            />
                          ) : (
                            <Button
                              style={
                                chosenFollowUpQuestion === text
                                  ? "active"
                                  : "primary"
                              }
                              text={text}
                              typewriter={{
                                id: "question-box-buttons",
                                isDone: buttonTextDone,
                                setIsDone: setButtonTextDone,
                                textReady: optionQuestionDone,
                              }}
                              onClick={() => {
                                // don't allow same question twice - may want a disabled style
                                if (
                                  streaming ||
                                  chosenFollowUpQuestion === text
                                ) {
                                  return;
                                }
                                setChosenFollowUpQuestion(text);
                                onSubmit(text, true);
                              }}
                              classes="!mr-[12px] mobile:mr-[0] mb-[0.75rem] mobile:text-left px-2 rounded-xl border"
                            />
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
          {(
            <div className={`mobile:pt-[0.75rem] desktop:pt-[1.25rem] mobile:pb-[1.5rem] desktop:pb-0 ${finished ? 'visible' : 'invisible'}`}>
              <label
                htmlFor="ask"
                className={classnames("sr-only", {
                  ["text-black"]: style === "default",
                  ["text-[#D6D6EB]"]: style === "lightTxt",
                })}
                aria-label="Ask a follow up or new question"
              >
                Ask a follow up or new question
              </label>
              <form className="relative flex gap-1" onSubmit={handleSubmit}>
                <input
                  aria-label="Search"
                  type="text"
                  name="ask"
                  id="ask"
                  className={classnames(
                    "mobile:text-sm tablet:text-lg block w-full border-0 !bg-transparent focus:bg-transparent focus:outline-none leading-4",
                    {
                      ["text-black"]: style === "default",
                      ["text-[#D6D6EB] placeholder-white placeholder-opacity-50"]: style === "lightTxt",
                    }
                  )}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                />
                <div className="flex gap-2 justify-end bottom-1">
                  <button
                    id="question-btn"
                    onClick={handleSubmit}
                    disabled={isEmpty(value)}
                    aria-label="Enter"
                    className="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:rounded"
                  >
                    <ArrowRightIcon arrowColour="fill-icons-arrowright" data-testid="arrow-button" />
                  </button>
                </div>
                <div
                  className="absolute inset-x-0 bottom-0 top-[30px] border-t border-neutral-400 peer-focus:border-t-2"
                  aria-hidden="true"
                />
              </form>
            </div>
          )}
        </div>
      </Grid>
    </div>
  );
};
