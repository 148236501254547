import { useNavigate } from "react-router-dom";
import { MovingSpinningCircles } from "../../components/Icons";
import { TypewriterText } from "../../components";
import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import { useConfig } from "../../context/config-context";
import { useChat } from '../../context/chat-context';

export default function InitialNew() {
  const navigate = useNavigate();
  const { config } = useConfig();
  const { questions, setResumed } = useChat();
  const [expanded, setExpanded] = useState(false);
  const [textIsDone, setTextIsDone] = useState(false);
  const pillRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const listener = (event: MessageEvent) => {

    console.log(event.origin.trim() === config.HOST.trim());
    console.log(event.origin.toLowerCase() === config.HOST.toLowerCase());
    console.log(new URL(event.origin).href === new URL(config.HOST).href);
    console.log(decodeURIComponent(event.origin) === decodeURIComponent(config.HOST));
    console.log("Iframe event", event);
    console.log("Iframe event origin", event.origin);
    console.log("config HOST", config.HOST);
    console.log("Iframe event data", event.data);   

    if (new URL(event.origin).href !== new URL(config.HOST).href) {        
      return;
    }

    if (event.data === "OPEN") {
      console.log("event.data === OPEN");
      setExpanded(true);

      setTimeout(() => {
        console.log("timeout fired");
        navigate("/streaming");
      }, 600);
      }
    };

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, [navigate]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    if (pillRef.current) {
      observer.observe(pillRef.current);
    }

    return () => {
      pillRef.current && observer.unobserve(pillRef.current);
    };
  }, []);

  return (
    <div
      className={classnames(
        "w-full h-screen justify-left flex items-end overflow-hidden",
        {
          "justify-left flex items-end bg-black/50 backdrop-blur-md transition-colors duration-1000 desktop:-pr-50px":
            expanded,
        }
      )}
    >
      <div
        ref={pillRef}
        className={classnames(
          "flex relative content-center items-center z-50 cursor-pointer text-white bg-black/80",
          {
            "pl-[1.75rem] pr-4 py-4 backdrop-blur-[20px] h-[78px] w-[380px] rounded-t-[50px] rounded-br-[50px] text-left":
              !expanded,
            "transition-all duration-500 justify-center mobile:h-screen tablet:h-[550px] desktop:w-[calc(100%-240px)] w-full inset-x-0 desktop:mx-[120px] rounded-t-[50px]":
              expanded,
          }
        )}
      >
        <div
          className={classnames("flex mr-[0.65rem] mt-[20px] justify-left", {
            block: !expanded,
            hidden: expanded,
          })}
        >
          <MovingSpinningCircles />
        </div>
        <TypewriterText
          id="initial"
          isDone={textIsDone}
          setIsDone={setTextIsDone}
          className={classnames("text-base whitespace-nowrap overflow-hidden", {
            block: !expanded,
            hidden: expanded,
          })}
          hasAllText
          text={
            width > 375
              ? "Hello! I'm a conversational AI. How can I help you today?"
              : "Hello! How can I help you today?"
          }
        />
      </div>
    </div>
  );
}
